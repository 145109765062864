<template>
  <div>
    <component
      :is="component"
      :param.sync="param"
      :educationDate.sync="educationDate"
      :educationInfo.sync="educationInfo"
      :saveCall.sync="saveCall"
      @setData="setData"
      @stepBack="stepBack"
    />
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'education-plan-tab',
  props: {
    param: {
      type: Object,
      default: () => ({
        eduPlanTime: [],
        plantCd: null,
        eduEducationId: '',
        eduCourseId: '',
        stepCd: '',
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      educationInfo: {
        eduEducationId: '',
        eduEducationYearPlanId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        educationStartTime: '',
        educationEndTime: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',
        checkAppr: '',
        // 위험성평가 결과 항목 추가
        ramRiskAssessmentPlanId: '',
        assessmentName: '',
        ramTechniqueCd: null,
        ramStepCd: '',
        sumEducationTime: '',

        // 자체감사 항목 추가
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
        selfInspectionTitle: '',

        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: null,
        
        regUserId: '',
        chgUserId: '',
        vod: null,

        eduSubjectList: [], // 과정별 교육과목
        delEduSubjectList: [],
        eduAttendeeList: [], // 대상자
        delEduAttendeeList: [],

        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      },
      saveCall: {
        saveCallData: '',
      },
      editable: true,
      splitter: 5,
      tab: 'educationPlanInfo',
      
      isComplete: false,
      deleteUrl: '',
      educationDate: '',
      component: () => import(`${'./educationPlanInfo.vue'}`),
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 40
      return numHeight + 'px';
    },
    disabled() {
      return this.educationInfo.documentStatusCd !== 'ESC000001'
        || this.educationInfo.approvalStatusCd === 'ASC0000001'
    },
  },
  watch: {
    'param.planUpdateBtnData.research'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.result.get.url;

      // code setting
      // if (this.param.eduEducationId) {
      //   this.tabItems = [
          // 기본정보
          // { key: uid(), name: 'educationPlanInfo', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./educationPlanInfo.vue'}`) },
          // 교육대상자
          // { key: uid(), name: 'educationResultTargetUser', icon: 'how_to_reg', label: 'LBL0002875', component: () => import(`${'./educationResultTargetUser.vue'}`), disabled: false },
          // 교육교재
          // { key: uid(), name: 'educationTextBook', icon: 'checklist', label: 'LBL0002809', component: () => import(`${'./educationTextBook.vue'}`), disabled: false },
      //   ]
      // } else {
      //   this.tabItems = [
          // 기본정보
          // { key: uid(), name: 'educationPlanInfo', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./educationPlanInfo.vue'}`) },
          // 교육대상자
          // { key: uid(), name: 'educationResultTargetUser', icon: 'how_to_reg', label: 'LBL0002875', component: () => import(`${'./educationResultTargetUser.vue'}`), disabled: true },
          // 교육교재
          // { key: uid(), name: 'educationTextBook', icon: 'checklist', label: 'LBL0002809', component: () => import(`${'./educationTextBook.vue'}`), disabled: true },
      //   ]
      // }
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.param.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = _result.data;
          this.educationInfo.eduAttendeeList = _result.data.eduAttendeeList
          this.param.eduPlanTime = [_result.data.educationStartTime, _result.data.educationEndTime]
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('emitStep', {
            name: 'setRegInfo',
            param: _result.data,
          })
          if (this.educationInfo.educationRangeFlag !== 'Y') {
            this.educationDate = this.educationInfo.educationStartDate
          }
          this.updateMode = true;
        },);
      } else if (this.param.eduCourseId) {
        this.$http.url = this.$format(selectConfig.sop.edu.course.get.url, this.param.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.forEach(this.educationInfo.eduSubjectList, item => {
            if (!this.educationInfo.delEduSubjectList) {this.educationInfo.delEduSubjectList = []}
            if (this.$_.findIndex(this.educationInfo.delEduSubjectList, { subjectNo: item.subjectNo }) === -1
              && item.editFlag !== 'C') {this.educationInfo.delEduSubjectList.push(item)}
            this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item)
          })
          // 빈값들 filter
          this.educationInfo.educationCourseName = _result.data.educationCourseName // 교육과정명
          // 교육과정 마스터 데이터 
          this.educationInfo.eduCourseId = _result.data.eduCourseId // 교육과정 key
          this.educationInfo.educationCycle = _result.data.educationCycle // 교육주기
          this.educationInfo.educationKindCdLarge = _result.data.educationKindCdLarge // 교육종류 대
          this.educationInfo.educationKindCdSmall = _result.data.educationKindCdSmall // 교육종류 소
          this.educationInfo.educationTypeCd = _result.data.educationTypeCd // 교육구분
          this.educationInfo.educationPurpose = _result.data.educationPurpose // 학습목적
          this.educationInfo.legalEducationFlag = _result.data.legalEducationFlag // 법정여부
          this.educationInfo.mainTargetAudience = _result.data.mainTargetAudience // 주요대상자
          this.educationInfo.relationLaws = _result.data.relationLaws // 관련법규
          this.educationInfo.executionBudget = _result.data.estimatedEducationExpenses // 예산
          this.educationInfo.eduQuestionFlag = _result.data.eduQuestionFlag
          this.educationInfo.eduQuestionMstId = _result.data.eduQuestionMstId
          this.educationInfo.eduQuestionPassScore = _result.data.eduQuestionPassScore
          this.educationInfo.educationLocation = _result.data.educationLocation
          this.educationInfo.educationMethodCd = _result.data.educationMethodCd
          this.educationInfo.vod = _result.data.vod
          this.educationInfo.educationName = _result.data.educationCourseName

          // this.attachInfo.taskKey = _result.data.eduCourseId
          // this.attachInfo.taskClassCd = 'EDU_CURRICULUM'

          if (!this.educationInfo.delEduSubjectList) {this.educationInfo.delEduSubjectList = [];}
          this.$_.forEach(this.educationInfo.eduSubjectList, (item) => {
            if (this.$_.findIndex(this.educationInfo.delEduSubjectList, {subjectNo: item.subjectNo,}) === -1 && item.editFlag !== "C"
            ) {this.educationInfo.delEduSubjectList.push(item);}
            this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item);
          });
          this.educationInfo.eduSubjectList = [];
          if (_result.data.eduCourseSubjectList && _result.data.eduCourseSubjectList.length > 0) {
            this.$_.forEach(_result.data.eduCourseSubjectList, _item => {
              let index = this.$_.findIndex(this.educationInfo.eduSubjectList, {subjectNo: _item.subjectNo,});
              if (index === -1) {
                this.educationInfo.eduSubjectList.push({
                  eduEducationId: '',
                  subjectNo: _item.subjecNo,
                  subjectName: _item.subjectName, // 교육명
                  educationTime: _item.educationTime, // 교육시간
                  instructorName: _item.instructorName,
                  regUserId: this.$store.getters.user.userId,
                  editFlag: 'C'
                })
              }
            })
          } 

          if (!this.educationInfo.delEduAttendeeList) {this.educationInfo.delEduAttendeeList = [];}
          this.$_.forEach(this.educationInfo.eduAttendeeList, (item) => {
            if (this.$_.findIndex(this.educationInfo.delEduAttendeeList, {attendeesId: item.attendeesId,}) === -1 && item.editFlag !== "C"
            ) {this.educationInfo.delEduAttendeeList.push(item);}
            this.educationInfo.eduAttendeeList = this.$_.reject(this.educationInfo.eduAttendeeList, item);
          });
          this.educationInfo.eduAttendeeList = [];
          if (_result.data.totalUserList && _result.data.totalUserList.length > 0) {
            this.$_.forEach(_result.data.totalUserList, _item => {
              let index = this.$_.findIndex(this.educationInfo.eduAttendeeList, {attendeesId: _item.userId,});
              if (index === -1) {
                this.educationInfo.eduAttendeeList.push({
                  eduEducationId: this.educationInfo.eduEducationId,
                  attendeesNo: uid(),
                  attendeesId: _item.userId,
                  attendeesTypeCd: 'EATC00003',
                  inExTypeCd: 'COURSE',
                  attendeesPositionName: _item.jobName,
                  attendanceTime: this.educationInfo.sumEducationTime,
                  attendeesDeptName: _item.deptName,
                  seniorityName: _item.seniorityName,
                  enterDate: _item.enterDate,
                  attendeesName: _item.userName,
                  regUserId: this.$store.getters.user.userId,
                  editFlag: 'C'
                })
              }
            })
          } 

        },);
      }
    },
    setData(data) {
      this.param.eduEducationId = data;
      this.getDetail();
    },
    stepBack() {
      this.$emit('emitStep', {
        name: 'currentStep',
        param: {
          step: 'ESC0000010', // 교육결과로 이동
          key: this.param.eduEducationId, // key
        }
      })
    },
  }
};
</script>
